// @ts-nocheck
import axios from "axios";
import JSONbig from "json-bigint";
import qs from "qs";

import { API_BASE_URL_VERSIONED } from "constants/env";
import { FieldValues, Command } from "./types";
// import { getAuth } from 'utils/auth'

export function executeCommand(parsedCommand: Command, fields: FieldValues) {
  const { endpoint, method } = parsedCommand;

  const { "2factorcode": token, ...restFields } = fields;

  // const auth = getAuth()
  // if (requireauth && !auth) {
  //   throw new Error('[console] Authentication required')
  // }

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const cancelToken = source.token;

  // if (auth['2factor'] && config['2factor']) {
  //   if (token) {
  //     params.token = token
  //   } else {
  //     throw new Error('[console] 2-factor code not provided')
  //   }
  // }
  if (method === "POST") {
    const promise = axios.request({
      url: endpoint || "",
      baseURL: API_BASE_URL_VERSIONED,
      method: "post",
      cancelToken,
      // headers: {
      //   ...headers,
      //   ...(
      //     requireauth ? {
      //       Authorization: auth.token,
      //     } : {}
      //   ),
      // },
      data: qs.stringify(restFields),
      transformResponse: [
        function (data) {
          return JSONbig({ storeAsString: true }).parse(data);
        },
      ],
    });

    return {
      promise,
      source,
    };
  } else {
    const params: string = restFields ? "?" + qs.stringify(restFields) : "";
    const promise = axios.request({
      url: `${endpoint}${params}` || "",
      baseURL: API_BASE_URL_VERSIONED,
      method: method,
      cancelToken,
      transformResponse: [
        function (data) {
          try {
            return JSONbig({ storeAsString: true }).parse(data);
          } catch (error) {
            throw Error(`Error parsing response JSON data: ${error.message}`);
          }
        },
      ],
    });

    return {
      promise,
      source,
    };
  }
}

export function handleAPICommand(parsedCommand: Command, fields: FieldValues) {
  const { promise, ...restValues } = executeCommand(parsedCommand, fields);

  // const promise = rawApiPromise.then((response: AxiosResponse) => {
  //   // if (parsedCommand.setauth) {
  //   //   const auth = getAuth()
  //   //   if (parsedCommand.parsedArgs.fields.email) {
  //   //     auth.email = parsedCommand.parsedArgs.fields.email
  //   //   }
  //   //   setAuth({
  //   //     ...auth,
  //   //     ...response.data,
  //   //   })
  //   // }
  //   return response.data
  // }).catch((error: any) => {
  //   return error.response.data.error
  // })

  return {
    promise,
    ...restValues,
  };
}
