import { Command } from "./types";
import { FIELD_TYPES } from "constants/fieldTypes";
import { parseCommand } from "./parser";

export function getPasswordInputFields(
  globalConfig: Command[],
  args: string[],
): string[] {
  if (!args.length) {
    return [];
  }

  // Skip if help command
  if (
    args[args.length - 1].length &&
    ["?", "help", "-m"].indexOf(args[args.length - 1]) >= 0
  ) {
    return [];
  }

  const { command } = parseCommand(args, globalConfig, {});
  if (!command.fields) return [];

  const passwordFields: string[] = [];
  for (let i = 0; i < command.fields.length; i += 1) {
    const field = command.fields[i];
    if (field.type === FIELD_TYPES.PASSWORD) {
      passwordFields.push(field.name || command.name);
    }
  }

  // const auth = getAuth()
  // if (config['2factor'] && auth['2factor']) {
  //   passwordFields.push('code')
  // }

  return passwordFields;
}
