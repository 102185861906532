import { Field, Command, Params } from "./types";
import { FIELD_TYPES } from "../constants/fieldTypes";
import { object, string, mixed, ValidationError } from "yup";

export const addFieldValidation = (shape: any, field: Field) => {
  // if (field.type === FIELD_TYPES.INTEGER) {
  //   let check = number().integer().label(field.name).typeError('you must specify a number')
  //   if (!!field.min) check = check.min(field.min)
  //   if (!!field.max) check = check.max(field.max)
  //
  //   shape[field.name] = check
  // }

  // integers are validated the same as strings, so min = 5 means minimum 5 characters
  if (field.type === FIELD_TYPES.INTEGER && field.type === FIELD_TYPES.TEXT) {
    let check = string().label(field.name);
    if (!!field.min) check = check.min(field.min);
    if (!!field.max) check = check.max(field.max);
    if (field.regex) check = check.matches(new RegExp(field.regex));

    shape[field.name] = check;
  }

  if (field.type === FIELD_TYPES.LIST) {
    const check = mixed().label(field.name).oneOf(field.list!);

    shape[field.name] = check;
  }

  if (field.required) {
    const check = shape[field.name] || mixed().label(field.name);

    if (field.requiredWhenNoOtherFields) {
      shape[field.name] = check.test(
        "required",
        `${[field.name, field.requiredWhenNoOtherFields].reverse().join(" or ")} is required`,
        function (this: any, value: any) {
          const { parent } = this;

          if (
            field.requiredWhenNoOtherFields!.some(
              (name: string) =>
                parent[name] !== null && parent[name] !== undefined,
            )
          )
            return true;

          return value !== null && value !== undefined;
        },
      );
    } else {
      shape[field.name] = check.required();
    }
  }
};

export const createValidationSchema = (chain: Command[]) => {
  const shape: any = {};

  chain.forEach((command) => {
    if (!command.fields) return;

    command.fields.forEach((field, i) => addFieldValidation(shape, field));
  });

  return object().shape(shape);
};

export const validateParams = (chain: Command[], params: Params) => {
  createValidationSchema(chain).validateSync(params);

  const allowedParamNames = chain
    .map((command) => (command.fields || []).map(({ name }) => name))
    .flat();

  for (const name in params) {
    if (!allowedParamNames.includes(name))
      throw new ValidationError(`parameter not found`, name, name);
  }
};
