export const inputToArguments = (input: string): string[] => {
  const args = [];
  let currentArg = "";
  let inQuote = false;
  let isMulti = false;
  for (let i = 0; i < input.length; i += 1) {
    if (input[i] === '"' || input[i] === "'") {
      inQuote = !inQuote;
      if (!inQuote) {
        args.push(currentArg);
        currentArg = "";
      }
    } else if (input[i] === "[" || input[i] === "]") {
      isMulti = !isMulti;
      if (input[i] === "]") {
        args.push(inputToArguments(currentArg));
        currentArg = "";
      }
    } else if (input[i] === " " && !inQuote && !isMulti) {
      if (currentArg) {
        args.push(currentArg);
        currentArg = "";
      }
    } else {
      currentArg += input[i];
    }
  }
  if (currentArg) {
    args.push(currentArg);
  }
  // @ts-ignore
  return args.map((string) => {
    if (string instanceof Array) {
      return string.map((s) => s.trim());
    } else {
      return string.trim();
    }
  });
};
