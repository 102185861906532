import { Command, Params, handleAPICommand } from "terminal";
import { createValidationSchema } from "terminal";
import "./index.css";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useKey } from "react-use";

type Props = {
  command: Command;
  chain: Command[];
  params: Params;
  close: () => void;
  printResult: (result: any) => void;
};

export default function FieldsModal({
  command,
  chain,
  params,
  close,
  printResult,
}: Props) {
  const wrapperRef = React.useRef(null);

  useKey("Escape", close);

  const yupResolverWrapper = (schema: any, options: any = {}) => {
    const resolver = yupResolver(schema, options);
    return (values: Record<string, any>, ...rest: any[]): any => {
      chain.forEach((command) =>
        command.fields?.forEach(({ type, name }) => {
          if (values[name] === "") delete values[name];
        }),
      );
      return resolver(values, ...rest);
    };
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: params,
    resolver: yupResolverWrapper(createValidationSchema(chain)),
  });

  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === wrapperRef.current) close();
  };

  const onSubmit = (values: any) => {
    const response = handleAPICommand(command, values);
    printResult(response);
    close();
  };

  return (
    <div
      className="modal-wrapper"
      ref={wrapperRef}
      onClick={handleWrapperClick}
    >
      <form className="modal" onSubmit={handleSubmit(onSubmit)}>
        <div className="close" onClick={close}>
          ×
        </div>
        {chain.map(({ name, fields }) => (
          <React.Fragment key={name}>
            <div key={name} className="header">
              {name}
            </div>
            <div className="body">
              {!fields && <div className="item">No fields</div>}
              {fields &&
                fields.map(({ name, required, type, list }) => (
                  <React.Fragment key={name}>
                    <label key={name} className="item">
                      <div className={`label${required ? " required" : ""}`}>
                        {name}
                      </div>
                      {type === "list" && (
                        <select ref={register} className="input" name={name}>
                          <option value="">-</option>
                          {list!.map((option) => (
                            <option>{option}</option>
                          ))}
                        </select>
                      )}
                      {["int", "text"].includes(type as string) && (
                        <input
                          ref={register}
                          className="input"
                          type={type === "int" ? "number" : "text"}
                          name={name}
                        />
                      )}
                      {type === "boolean" && (
                        <div className="checkbox-wrap">
                          <input
                            ref={register}
                            className="checkbox"
                            type="checkbox"
                            name={name}
                          />
                        </div>
                      )}
                    </label>
                    {errors[name]?.message && (
                      <div className="item error">{errors[name]?.message}</div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </React.Fragment>
        ))}
        <div className="footer">
          <div className="btn outline">Cancel</div>
          <button type="submit" className="btn primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
