import React, { useCallback, useState, useRef, useEffect } from "react";
import { AxiosError, CancelTokenSource } from "axios";
import { useKey } from "react-use";

import {
  AuthResponsePayload,
  inputToArguments,
  autoCompleteSuggestions,
  getPasswordInputFields,
  handleCommand,
  Command,
  Params,
  ParserError,
} from "terminal";
import { getAuth } from "utils/auth";
import { getEqualSubstring } from "utils/string";
import "./index.css";
import Spinner from "./Spinner";
import FieldsModal from "../FieldsModal";

type Props = {
  globalConfig: Command[];
  theme: string;
  welcomeMessage: string;
};

type SpecialKeyHandlers = {
  Backspace: () => void;
  Enter: () => void;
  Tab: () => void;
  Escape: () => void;
  ArrowUp: () => void;
  ArrowDown: () => void;
  ArrowLeft: () => void;
  ArrowRight: () => void;
  Delete: () => void;
  [key: string]: () => void;
};

type ComboKey = "metaKey" | "ctrlKey";

type CombinationKeyHandlers = {
  k: {
    metaKey: () => void;
  };
  c: {
    metaKey: null;
    ctrlKey: () => void;
  };
  [key: string]: {
    metaKey: (() => void) | null;
    ctrlKey?: () => void;
  };
};

const INPUT_MODE = {
  COMMAND: "command",
  PASSWORD: "password",
  LOGIN_2FA_CODE: "login_2fa_code",
  LOGIN_2FA_MODE: "login_2fa_mode",
};

const maskInputValue = (value: string, mask = true) =>
  mask ? Array(value.length).fill("*").join("") : value;

const twoFactorPrompt = (input: string) =>
  `Choose how to get 2-factor code:<br />
  1) Get code by phone call<br />
  2) Get code by phone SMS<br />
  3) Get code by Authy app<br />
  Enter the number: ${input}`;

const Terminal: React.FC<Props> = (props) => {
  const { globalConfig, theme, welcomeMessage } = props;
  // messages displayed in terminal
  const [output, setOutput] = useState([welcomeMessage]);
  // terminal input value
  const [input, setInput] = useState("");
  const [inputMode, setInputMode] = useState(INPUT_MODE.COMMAND);
  const [cursorPos, setCursorPos] = useState(0);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const [modalData, setModalData] = useState<
    | {
        onSuccess: Function;
        command: Command;
        chain: Command[];
        params: Params;
      }
    | undefined
  >(undefined);

  /* Handling api command */
  const [processing, setProcessing] = useState(false);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(
    null,
  );

  /* Handling entering passwords */
  const [storedInput, setStoredInput] = useState("");
  const [passwordInputFields, setPasswordInputFields] = useState<string[]>([]);
  const [passwordValues, setPasswordValues] = useState<{ string?: string }>({});

  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const auth = getAuth();
  const terminalPrefix = `${auth ? auth.email + " " : ""}$ `;
  const currentTerminalPrefix =
    inputMode === INPUT_MODE.PASSWORD
      ? `${passwordInputFields[0]}: `
      : terminalPrefix;

  /* Utility functions */

  const print = (message: string, preformatted = false): void => {
    if (preformatted) {
      message = `<pre>${message}</pre>`;
    }
    setOutput((output) => output.concat([message]));
  };

  const changeInput = (newInput: string) => {
    setInput(newInput);
    setCursorPos(newInput.length + 1);
  };

  const recordCommand = (cmd: string) => {
    const trimmedCmd = cmd.trim();
    if (trimmedCmd) {
      setHistory((history) => history.concat([trimmedCmd]));
      setHistoryIndex(history.length + 1);
    }
  };

  const changeHistoryIndex = (inc: number) => {
    const newIndex = Math.min(Math.max(historyIndex + inc, 0), history.length);
    setHistoryIndex(newIndex);
    if (newIndex >= 0 && newIndex < history.length) {
      changeInput(history[newIndex]);
    } else {
      changeInput("");
    }
  };

  const clearScreen = () => setOutput([]);

  const handlePrintResult = (result: any, onSuccess: Function) => {
    if (!result) {
      return;
    } else if (typeof result === "string") {
      print(result);
    } else if (result.promise) {
      setProcessing(true);
      setCancelSource(result.source);

      result.promise.then(onSuccess).catch((error: AxiosError) => {
        if (error.response) {
          print(`HTTP Status code: ${error.response.status}`);
          print("Response:");
          print(JSON.stringify(error.response.data, null, 2), true);
        } else {
          print(`<span style="color: #e91e3f">${error.message}</span>`);
        }
        setProcessing(false);
        setCancelSource(null);
        setInput("");
        setInputMode(INPUT_MODE.COMMAND);
      });
    }
  };

  const executeAPICommand = (
    input: string,
    passwordValues: { string?: string },
    onSuccess: (data: { data: any }) => void,
  ) => {
    const result = handleCommand(globalConfig, input);
    if (result.openModal) {
      setModalData({
        ...result,
        onSuccess,
      });
    } else {
      handlePrintResult(result, onSuccess);
    }
  };

  const executeCommand = (
    input: string,
    passwordValues: { string?: string } = {},
  ): void => {
    executeAPICommand(
      input,
      passwordValues,
      ({ data }: { data: AuthResponsePayload }) => {
        if (data.auth_token && inputMode === INPUT_MODE.LOGIN_2FA_CODE) {
          // 2-factor code verification success
          print("Logged in successfully!");
          setInput("");
          setInputMode(INPUT_MODE.COMMAND);
        } else if (data.auth_token && data.result) {
          // Login success
          if (!data["2factor"]) {
            print("Logged in successfully!");
          } else {
            setInputMode(INPUT_MODE.LOGIN_2FA_MODE);
          }
        } else {
          print(JSON.stringify(data, null, 2), true);
        }
        setProcessing(false);
        setCancelSource(null);
      },
    );
  };

  /* Special key press event handlers */

  const handleBackspace = () => {
    if (cursorPos > 0) {
      setInput((val) => val.substr(0, cursorPos - 1) + val.substr(cursorPos));
      setCursorPos((val) => val - 1);
    }
  };

  const handleDelete = () => {
    setInput((val) => val.substr(0, cursorPos) + val.substr(cursorPos + 1));
  };

  const handleEnter = () => {
    if (inputMode === INPUT_MODE.PASSWORD) {
      const newPasswordValues = {
        ...passwordValues,
        [passwordInputFields[0]]: input,
      };
      const newPasswordInputFields = passwordInputFields.slice(1);
      print(
        `${currentTerminalPrefix} ${maskInputValue(input, passwordInputFields[0] !== "code")}`,
      );
      setInput("");
      setCursorPos(0);

      if (!newPasswordInputFields.length) {
        setInputMode(INPUT_MODE.COMMAND);
        setPasswordInputFields([]);
        setPasswordValues({});
        executeCommand(storedInput, newPasswordValues);
      } else {
        setPasswordValues(newPasswordValues);
        setPasswordInputFields(newPasswordInputFields);
      }

      return;
    } else if (inputMode === INPUT_MODE.LOGIN_2FA_MODE) {
      if (["1", "2", "3"].indexOf(input) === -1) {
        print("Invalid entry. Please try again.");
        setInput("");
        return;
      }

      print(twoFactorPrompt(input));

      if (input === "1" || input === "2") {
        const command =
          input === "1" ? "auth login sendcall" : "auth login sendsms";
        executeAPICommand(
          command,
          {},
          ({ data }: { data: { result: boolean } }) => {
            if (data.result) {
              setInput("");
              setInputMode(INPUT_MODE.LOGIN_2FA_CODE);
            } else {
              print("Failed to send 2-factor code. Aborting...");
              setInput("");
              setInputMode(INPUT_MODE.COMMAND);
            }

            setProcessing(false);
            setCancelSource(null);
          },
        );
      } else {
        setInput("");
        setInputMode(INPUT_MODE.LOGIN_2FA_CODE);
      }
    } else if (inputMode === INPUT_MODE.LOGIN_2FA_CODE) {
      print(`2-factor code: ${input}`);
      executeCommand(`auth login verifycode ${input}`);
    } else {
      // inputMode === INPUT_MODE.COMMAND
      recordCommand(input);
      setInput("");
      setCursorPos(0);

      if (input.trim() === "clear") {
        clearScreen();
        return;
      }

      if (!input.trim()) {
        print(processing ? "&nbsp;" : `${currentTerminalPrefix}${input}`);
        return;
      }

      print(`${currentTerminalPrefix}${input}`);

      const inputArgs = inputToArguments(input);

      try {
        const passwordFields = getPasswordInputFields(globalConfig, inputArgs);
        if (passwordFields.length) {
          setPasswordInputFields(passwordFields);
          setPasswordValues({});
          setStoredInput(input);
          setInputMode(INPUT_MODE.PASSWORD);
        } else {
          setInputMode(INPUT_MODE.COMMAND);
          setPasswordInputFields([]);
          setPasswordValues({});
          executeCommand(input);
        }
      } catch (error) {
        if (error instanceof ParserError) {
          print(error.message);
          return;
        }
        console.error(error);
        print("Unexpected error occurred. Please check browser console.");
      }
    }
  };

  const handleTab = () => {
    if (!input || inputMode !== INPUT_MODE.COMMAND) {
      return;
    }

    const args = inputToArguments(input);
    const last = args[args.length - 1];
    const suggestions = autoCompleteSuggestions(globalConfig, args);
    if (suggestions.length === 0) return;

    if (suggestions.length === 1)
      changeInput(
        args
          .slice(0, args.length - 1)
          .concat(suggestions)
          .join(" ") + " ",
      );

    print(`${terminalPrefix}${input}`);
    print(suggestions.join(" "));
    let common = suggestions[0];
    for (let i = 1; i < suggestions.length; i += 1) {
      common = getEqualSubstring(common, suggestions[i]);
    }
    setInput((val) =>
      val.trim().split(" ").slice(0, -1).concat([common]).join(" "),
    );
    setCursorPos((val) => val - last.length + common.length);
  };

  useEffect(() => {
    const helpList = document.getElementsByClassName("Terminal__helpCommand");
    const widthList = [];
    let i = 0;
    for (; i < helpList.length; i += 1) {
      if (!helpList[i].classList.contains("fixed")) {
        // @ts-ignore
        widthList.push(helpList[i].clientWidth);
      }
    }
    const maxWidth = Math.max(...widthList) || 0;
    i = 0;
    for (; i < helpList.length; i += 1) {
      if (!helpList[i].classList.contains("fixed")) {
        // @ts-ignore
        helpList[i].style.width = `${maxWidth + 20}px`;
        helpList[i].classList.add("fixed");
      }
    }
  }, [output]);

  const handleEsc = () => {
    if (inputMode !== INPUT_MODE.COMMAND) {
      return;
    }
    setInput("");
    setCursorPos(0);
  };

  const handleArrowUp = () => {
    if (historyIndex > 0 && inputMode === INPUT_MODE.COMMAND) {
      changeHistoryIndex(-1);
    }
  };

  const handleArrowDown = () => {
    if (historyIndex < history.length && inputMode === INPUT_MODE.COMMAND) {
      changeHistoryIndex(1);
    }
  };

  const handleArrowLeft = () => {
    if (cursorPos > 0 && inputMode === INPUT_MODE.COMMAND) {
      setCursorPos((val) => val - 1);
    }
  };

  const handleArrowRight = () => {
    if (cursorPos < input.length && inputMode === INPUT_MODE.COMMAND) {
      setCursorPos((val) => val + 1);
    }
  };

  const specialKeyHandlers: SpecialKeyHandlers = {
    Backspace: handleBackspace,
    Enter: handleEnter,
    Tab: handleTab,
    Escape: handleEsc,
    ArrowUp: handleArrowUp,
    ArrowDown: handleArrowDown,
    ArrowLeft: handleArrowLeft,
    ArrowRight: handleArrowRight,
    Delete: handleDelete,
  };

  /* Combination key handlers */

  const handlePasteEvent = useCallback(
    (evt: any) => {
      const clipData = evt.clipboardData;
      const text = clipData ? clipData.getData("text/plain") : "";
      if (text) {
        setInput(
          (val) => val.substr(0, cursorPos) + text + val.substr(cursorPos),
        );
        setCursorPos((pos) => pos + text.length);
      }
    },
    [cursorPos, setInput],
  ); // Add all the dependencies here

  useEffect(() => {
    document.addEventListener("paste", handlePasteEvent);
    return () => {
      document.removeEventListener("paste", handlePasteEvent);
    };
  }, [handlePasteEvent]); // handlePasteEvent is now stable between renders

  const handleAbort = () => {
    if (cancelSource) {
      print("Command aborted!");
      cancelSource!.cancel();
      setProcessing(false);
      setInputMode(INPUT_MODE.COMMAND);
      setInput("");
    } else if (inputMode === INPUT_MODE.PASSWORD) {
      print(
        `${currentTerminalPrefix} ${maskInputValue(input, passwordInputFields[0] !== "code")}`,
      );
      setInputMode(INPUT_MODE.COMMAND);
      setPasswordInputFields([]);
      setPasswordValues({});
      setStoredInput("");
      setInput("");
    } else if (inputMode === INPUT_MODE.COMMAND) {
      print(`${terminalPrefix}${input}`);
      setInput("");
    } else if (inputMode === INPUT_MODE.LOGIN_2FA_MODE) {
      print(twoFactorPrompt(input));
      setInputMode(INPUT_MODE.COMMAND);
      setInput("");
    } else if (inputMode === INPUT_MODE.LOGIN_2FA_CODE) {
      print(`2-factor code: ${input}`);
      setInputMode(INPUT_MODE.COMMAND);
      setInput("");
    }
  };

  const combinationKeyHandlers: CombinationKeyHandlers = {
    k: {
      metaKey: clearScreen,
    },
    c: {
      metaKey: null,
      ctrlKey: handleAbort,
    },
  };

  const handleKey = (ev: KeyboardEvent) => {
    if (ev.key.length === 1) {
      if (combinationKeyHandlers[ev.key]) {
        const comboKeys: ComboKey[] = ["metaKey", "ctrlKey"];
        for (let i = 0; i < comboKeys.length; i += 1) {
          const comboKey: ComboKey = comboKeys[i];
          if (ev[comboKey] && combinationKeyHandlers[ev.key][comboKey]) {
            combinationKeyHandlers[ev.key][comboKey]!();
            break;
          }
          if (
            ev[comboKey] &&
            combinationKeyHandlers[ev.key][comboKey] === null
          ) {
            return;
          }
        }
      }
      if (!(ev.metaKey || ev.ctrlKey || ev.altKey || processing)) {
        ev.preventDefault();

        if (ev.key === "?" && inputMode === INPUT_MODE.COMMAND) {
          print(`${currentTerminalPrefix}${input}?`);
          executeCommand(input + "?");
        } else {
          setInput(
            (val) => val.substr(0, cursorPos) + ev.key + val.substr(cursorPos),
          );
          setCursorPos((pos) => pos + 1);
        }
      }
    } else if (specialKeyHandlers[ev.key]) {
      ev.preventDefault();
      specialKeyHandlers[ev.key]();
    }
  };

  /* Effect Hooks */

  useKey(() => !modalData, handleKey, {}, [
    globalConfig,
    input,
    cursorPos,
    cancelSource,
    inputMode,
    storedInput,
    passwordInputFields,
    passwordValues,
    modalData,
  ]);

  useEffect(() => {
    scrollWrapperRef.current!.scrollTop = 999999;
  });

  return (
    <div className={`Terminal Terminal--${theme}`}>
      <div className="Terminal__scrollWrapper" ref={scrollWrapperRef}>
        {output.map((message, index) => (
          <div key={`${index}_${message}`} className="Terminal__output">
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        ))}

        {processing ? (
          <Spinner />
        ) : (
          <div className="Terminal__input">
            {inputMode === INPUT_MODE.LOGIN_2FA_MODE && (
              <span
                dangerouslySetInnerHTML={{
                  __html: twoFactorPrompt(input),
                }}
              />
            )}

            {inputMode === INPUT_MODE.LOGIN_2FA_CODE && (
              <span>2-factor code: {input}</span>
            )}

            {(inputMode === INPUT_MODE.COMMAND ||
              inputMode === INPUT_MODE.PASSWORD) && (
              <span>
                {currentTerminalPrefix}
                {maskInputValue(
                  input.substr(0, cursorPos),
                  inputMode === INPUT_MODE.PASSWORD &&
                    passwordInputFields[0] !== "code",
                )}
                <span className="Terminal__cursor" />
                {maskInputValue(
                  input.substr(cursorPos),
                  inputMode === INPUT_MODE.PASSWORD &&
                    passwordInputFields[0] !== "code",
                )}
              </span>
            )}
          </div>
        )}
      </div>
      {modalData && (
        <FieldsModal
          {...modalData}
          close={() => setModalData(undefined)}
          printResult={(result) =>
            handlePrintResult(result, modalData.onSuccess)
          }
        />
      )}
    </div>
  );
};

Terminal.defaultProps = {
  theme: "dark",
};

export default Terminal;
