import { Command, Params } from "./types";
import { FIELD_TYPES } from "../constants/fieldTypes";

type ParsedCommand = {
  command: Command;
  chain: Command[];
  params: Params;
};

type Args = string[] & {
  current: number;
  haveNext(): boolean;
};

export class ParserError extends Error {}

export class CommandError extends ParserError {
  constructor(
    message: string,
    public commands: Command[],
  ) {
    super(message);
  }
}

const findCommand = (args: Args, commands: Command[]) => {
  const name = args[args.current++].toLowerCase();
  const command = commands.find((command) => command.name === name);

  if (!command)
    throw new CommandError(`Invalid command entered: ${name}`, commands);

  return command;
};

const parseParams = (args: Args, command: Command) => {
  const params: Params = {};

  if (!args.haveNext()) return params;

  if (command.defaultField)
    params[command.defaultField.name] = args[args.current++];

  const fields = command.fields || [];

  while (true) {
    let arg = args[args.current];
    if (!arg) break;

    if (arg.startsWith("--")) arg = arg.slice(2);

    const field = fields.find((field) => field.name === arg);
    if (!field) break;

    args.current++;
    let value = args[args.current];
    if (!value && field.type === FIELD_TYPES.BOOLEAN && value.startsWith("--"))
      value = "true";
    else args.current++;

    params[arg] = value;
  }

  return params;
};

const parseItem = (args: Args, commands: Command[]): ParsedCommand => {
  let command = findCommand(args, commands);
  const chain = [command];
  const params = parseParams(args, command);

  if (command.subcommands && args.haveNext()) {
    const subcommand = parseItem(args, command.subcommands);
    command = subcommand.command;
    chain.push(...subcommand.chain);
    Object.assign(params, subcommand.params);
  }

  return { command, chain, params };
};

export const parseCommand = <T extends Record<string, string>>(
  stringArgs: string[],
  commands: Command[],
  flags: T,
) => {
  const args = stringArgs as any as Args;
  args.current = 0;
  args.haveNext = () => args.current < args.length;

  const lastArg = args[args.length - 1];
  const flagsValues = Object.fromEntries(
    Object.keys(flags).map((key) => [key, lastArg === flags[key]]),
  ) as Record<keyof T, boolean>;

  if (Object.values(flagsValues).includes(true)) args.pop();

  return Object.assign(flagsValues, parseItem(args, commands));
};
