import { Command, Field } from "./types";
import { FIELD_TYPES } from "constants/fieldTypes";

const getFieldsHelp = (fields?: Field[]) => {
  if (!fields) return "";

  return fields
    .map((field) => {
      const listValues =
        field.type === FIELD_TYPES.LIST && field.list
          ? ` (${field.list.join(" | ")})`
          : "";

      let requiredMessage: string;
      if (field.required)
        if (field.requiredWhenNoOtherFields) {
          const word =
            field.requiredWhenNoOtherFields.length > 1 ? "are" : "is";
          requiredMessage = `required if ${field.requiredWhenNoOtherFields.join(", ")} ${word} not provided`;
        } else {
          requiredMessage = "required";
        }
      else requiredMessage = "optional";

      return (
        `<span class="Terminal__helpCommand">${field.name} &lt;${field.name}&gt;</span>` +
        `${field.description}${listValues}, ${requiredMessage}</br>`
      );
    })
    .join("");
};

export function handleHelpCommand(
  globalConfig: Command[],
  commandConfig: Command,
  commandArgs: string[] | null = null,
  isRequiredFieldsValid: boolean,
) {
  const config: Command = commandConfig || {
    subcommands: globalConfig,
  };
  let hasRequiredFields: boolean = false;
  if (config && config.fields) {
    config.fields.forEach((field) => {
      if (field instanceof Array) {
        field.forEach((f) => {
          if (f.required && !hasRequiredFields) {
            hasRequiredFields = true;
          }
        });
      } else if (field.required && !hasRequiredFields) {
        hasRequiredFields = true;
      }
    });
  }

  let output = "";

  if (config.description) {
    output += `${config.description}<br />`;
    output += "<br />";
  }

  if (config.name && (!isRequiredFieldsValid || !hasRequiredFields)) {
    output += `Usage:<br />`;
    output += (commandArgs || [])
      .concat(
        (config.fields || []).map((field) => {
          return `<em>${field.name} &lt;${field.name}&gt;</em>`;
        }),
      )
      .join(" ");
    if (config.subcommands) {
      output += `<em>${config.endpoint ? " [subcommand]" : " subcommand"}</em>`;
      output += " ...";
    }
    output += "<br />";

    output += getFieldsHelp(config.fields);
  }

  if (
    config.subcommands &&
    (isRequiredFieldsValid === undefined || isRequiredFieldsValid)
  ) {
    if (output) {
      output += `<br />`;
    } else if (config.description) {
      output += `${config.description}<br /><br />`;
    }

    output += "Subcommands:<br />";
    output += config.subcommands.reduce((output, command) => {
      return (
        output +
        `<span class="Terminal__helpCommand">${command.name}</span>` +
        `${command.description}<br/>`
      );
    }, "");
  }

  return output;
}
