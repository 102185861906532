import { handleHelpCommand } from "./help";
import { Command } from "./types";
import { inputToArguments } from "./utils";
import { validateParams } from "./validate";
import { ValidationError } from "yup";
import { handleAPICommand } from "./api";
import { ParserError, parseCommand } from "./parser";

type SpecialHandlers = {
  help: Function;
  "?": Function;
  clear: Function;
  [key: string]: Function;
};

const specialHandlers: SpecialHandlers = {
  help: handleHelpCommand,
  "?": handleHelpCommand,
  clear: () => 1,
};

export function handleCommand(globalConfig: Command[], input: string) {
  const command = input.trim();
  if (specialHandlers[command]) return specialHandlers[command](globalConfig);

  const args = inputToArguments(input);

  try {
    const { command, chain, params, help, modal } = parseCommand(
      args,
      globalConfig,
      {
        help: "?",
        modal: "-m",
      },
    );

    if (modal) return { command, chain, params, openModal: true };

    if (help) {
      let isValid = true;
      try {
        validateParams(chain, params);
      } catch (e) {
        isValid = false;
      }
      return handleHelpCommand(globalConfig, command, args, isValid);
    }

    validateParams(chain, params);

    if (command.endpoint) return handleAPICommand(command, params);

    if (command.subcommands)
      return handleHelpCommand(globalConfig, command, args, true);

    throw new ParserError(
      `Command ${command.name} has no endpoint, neither subcommands `,
    );
  } catch (error) {
    if (error instanceof ParserError) return error.message;

    if (error instanceof ValidationError) return `${error.message}`;

    console.error(error);
    return "Unexpected error occurred. Please check browser console.";
  }
}
